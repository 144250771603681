import styled from 'styled-components';
import { Box } from '../../common/Box';
import BackgroundImage from 'assets/backgrounds/recommendations.jpg';
import { Typography } from '../../common/Typography';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position-x: 89%;

  height: fit-content;
  padding: ${SIZES.spacing(8)} ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    padding: ${SIZES.spacing(12.5)} 0;
    background-position-x: unset;
  }
`;

export const StyledDescription = styled(Typography)`
  line-height: 25px;

  ${SIZES.media.phone} {
    width: 60%;
  }
`;
