import { FC, useState } from 'react';
import { NavigationMenuProps } from './NavigationMenu.d';
import { Box } from '../Box';
import { StyledButton, StyledLink } from './NavigationMenu.styled';
import { ExpandedNavMenu } from './ExpandedNavMenu';
import { navigationItems } from './config';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { COLORS } from '../../../theme/colors';
import { AppPaths } from 'urls/frontend';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import { Button } from '../Button';
import { ButtonSize, ButtonVariant } from '../Button/Button.enums';
import { MobileNavigationMenu } from './MobileNavigationMenu';

export const NavigationMenu: FC<NavigationMenuProps> = ({
  variant,
  expandableMenuVariant,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const isPrimaryVariant = variant === 'primary';
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [aboutUsAnchorEl, setAboutUsAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [methodologyAnchorEl, setMethodologyAnchorEl] =
    useState<null | HTMLElement>(null);
  const openAboutUs = Boolean(aboutUsAnchorEl);
  const openMethodology = Boolean(methodologyAnchorEl);

  const handleMouseEnterAboutUs = (event: React.MouseEvent<HTMLElement>) => {
    !!openMethodology && setMethodologyAnchorEl(null);
    setAboutUsAnchorEl(event.currentTarget);
  };

  const handleMouseEnterMethodology = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    !!openAboutUs && setAboutUsAnchorEl(null);
    setMethodologyAnchorEl(event.currentTarget);
  };

  const handleMouseLeaveAboutUs = () => {
    setAboutUsAnchorEl(null);
  };

  const handleMouseLeaveMethodology = () => {
    setMethodologyAnchorEl(null);
  };

  return (
    <>
      <MobileNavigationMenu
        open={isMobileMenuOpen}
        setOpen={setIsMobileMenuOpen}
      />
      {isMobile ? (
        <>
          <Button
            variant={
              isPrimaryVariant
                ? ButtonVariant.SECONDARY
                : ButtonVariant.OUTLINED
            }
            size={ButtonSize.SMALL}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            Menu
          </Button>
        </>
      ) : (
        <Box display="flex" alignItems="center" gap={4}>
          <StyledButton
            open={openAboutUs}
            variant={variant}
            onClick={(e) => {
              e.stopPropagation();
              navigate(AppPaths.aboutUs);
              handleMouseLeaveAboutUs();
            }}
            onMouseOver={handleMouseEnterAboutUs}
          >
            <>Über uns</>
            <ArrowIcon
              stroke={
                isPrimaryVariant
                  ? COLORS.typography.main
                  : COLORS.background.secondary
              }
            />
          </StyledButton>
          <ExpandedNavMenu
            variant={expandableMenuVariant}
            categoryName={navigationItems.aboutUs.categoryName}
            menuItems={navigationItems.aboutUs.items}
            anchorEl={aboutUsAnchorEl}
            open={openAboutUs}
            setAnchorEl={setAboutUsAnchorEl}
          />
          <StyledButton
            open={openMethodology}
            variant={variant}
            onClick={(event) => {
              event.preventDefault();
              navigate(AppPaths.methodology);
              handleMouseLeaveMethodology();
            }}
            onMouseOver={handleMouseEnterMethodology}
          >
            <>Methodik</>
            <ArrowIcon
              stroke={
                isPrimaryVariant
                  ? COLORS.typography.main
                  : COLORS.background.secondary
              }
            />
          </StyledButton>
          <ExpandedNavMenu
            variant={expandableMenuVariant}
            categoryName={navigationItems.methodology.categoryName}
            menuItems={navigationItems.methodology.items}
            anchorEl={methodologyAnchorEl}
            open={openMethodology}
            setAnchorEl={setMethodologyAnchorEl}
          />
          <StyledLink to={AppPaths.contacts} variant={variant}>
            Kontakt
          </StyledLink>
        </Box>
      )}
    </>
  );
};
