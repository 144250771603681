import { FC } from 'react';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledHeader,
  StyledInnerContainer,
  StyledSubHeader,
  StyledTagsContainer,
  StyledTopContainer,
  StyledTopInnerContainer,
} from './MainScreen.styled';
import { Header } from 'components/common/Header';
import { Box } from '../../common/Box';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { COLORS } from '../../../theme/colors';
import { tags } from './config';
import { Chip } from '../../common/Chip';
import { hexToRGB } from '../../../utils/common';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactMainSectionAction,
} from 'utils/google-analytics/events/contacts';
import { AboutUs } from './AboutUs';
import { useWindowSize } from 'hooks/useWindowSize';

export const MainScreen: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const onContactSalesClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactMainSectionAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <>
      <StyledContainer>
        <Header variant="secondary" />
        <StyledInnerContainer>
          <StyledTopContainer
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <StyledTopInnerContainer>
              <StyledHeader variant="h1">Smarte Fondsselektion</StyledHeader>
              <StyledSubHeader variant="body">
                Mehrwert durch unabhängige Fondsanalyse Innovativ & Bewährt
              </StyledSubHeader>
              <StyledButtonContainer
                display="flex"
                gap={1.1}
                alignItems="center"
                pt={6}
              >
                <Button
                  variant={ButtonVariant.CONTAINED}
                  onClick={onContactSalesClick}
                >
                  Kontaktieren Sie uns
                </Button>
              </StyledButtonContainer>
            </StyledTopInnerContainer>
            <StyledTagsContainer display="flex" gap={1} flexWrap="wrap">
              {tags.map((tag) => (
                <Chip
                  label={tag}
                  customColor={COLORS.background.secondary}
                  customBackground={hexToRGB(
                    COLORS.background.secondary,
                    '0.25',
                  )}
                  customBorderBolor="none"
                  customSize="large"
                />
              ))}
            </StyledTagsContainer>
          </StyledTopContainer>
          {!isMobile && <AboutUs />}
        </StyledInnerContainer>
      </StyledContainer>
      {!!isMobile && <AboutUs />}
    </>
  );
};
