import styled, { css } from 'styled-components';
import { Box } from '../Box';
import { COLORS } from '../../../theme/colors';
import { Typography } from '../Typography';
import { HashLink as Link } from 'react-router-hash-link';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled.footer`
  background-color: ${COLORS.typography.primary};
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)};
  position: relative;

  ${SIZES.media.phone} {
    padding: ${SIZES.spacing(8.5)} ${SIZES.spacing(8)} ${SIZES.spacing(5)}
      ${SIZES.spacing(8)};
  }
`;

export const StyledTopContainer = styled(Box)`
  ${SIZES.media.phone} {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 300px 415px auto;
  }
`;

export const StyledTopFirstContainer = styled(Box)`
  gap: ${SIZES.spacing(4.5)};

  ${SIZES.media.phone} {
    gap: ${SIZES.spacing(4)};
  }
`;

export const StyledNavLinksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.spacing(4)};
  padding-top: ${SIZES.spacing(5)};

  ${SIZES.media.phone} {
    padding-top: 0;
    display: grid;
    grid-gap: ${SIZES.spacing(4)};
    grid-template-columns: 1fr 1fr;
  }
`;

const typographyStyles = css`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: ${COLORS.typography.neutral};
`;

export const StyledTypography = styled(Typography)`
  ${typographyStyles}
`;

export const StyledTitle = styled(Typography)`
  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
`;

export const StyledLegalInfoLink = styled(Link)`
  ${typographyStyles}
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled.a`
  ${typographyStyles}
  font-size: 14px;
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledNavLink = styled(Link)`
  ${typographyStyles}
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledBottomContainer = styled(Box)`
  flex-direction: column;
  padding-top: ${SIZES.spacing(4)};
  gap: ${SIZES.spacing(4)};

  ${SIZES.media.phone} {
    padding-top: ${SIZES.spacing(12)};
    flex-direction: row;
    gap: 0;
    align-items: center;
  }
`;

export const StyledBottomInnerContainer = styled(Box)`
  flex-direction: column;

  ${SIZES.media.phone} {
    flex-direction: row;
  }
`;

export const StyledArrowIconButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;

  width: 48px;
  height: 48px;

  background-color: ${COLORS.info.selectedBorder};

  border-radius: 24px;
  border: none;
  cursor: pointer;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;
`;
