import { FC } from 'react';
import {
  StyledContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledTypography,
} from './Studies.styled';
import { Typography } from '../../common/Typography';
import { COLORS } from '../../../theme/colors';
import { Box } from '../../common/Box';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  StudiesCategory,
  StudiesClickedAction,
} from 'utils/google-analytics/events/studies';
import { APP_URL } from 'urls/api';
import {
  ContactAboutUsAction,
  ContactCategory,
} from 'utils/google-analytics/events/contacts';
import { AppPaths } from 'urls/frontend';
import { useNavigate } from 'react-router-dom';

export const Studies: FC = () => {
  const navigate = useNavigate();
  const onGoTiStuidesClick = () => {
    TrackGoogleAnalyticsEvent(
      StudiesCategory,
      StudiesClickedAction,
      window.location.pathname,
    );
    window.location.href = APP_URL;
  };

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactAboutUsAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer gridGap={2.5}>
      <StyledLeftContainer>
        <Typography variant="h2" color={COLORS.background.secondary}>
          Studien - umfassend und aktuell
        </Typography>
        <Box display="flex" flexDirection="column" gap={5}>
          <StyledTypography variant="body" color={COLORS.background.secondary}>
            Wir erstellen exklusiv Studien über Fonds und Asset Management
            Themen in einer kompakten und transparenten Form. Sie eignen sich
            für professionelle Berater wie auch für interessierte Anleger und
            Leser und liefern eine belastbare Basis für Anlageentscheidungen.
            Eine Übersicht zu den aktuellen FondsConsult-Studien finden Sie hier
          </StyledTypography>
          <Button
            variant={ButtonVariant.CONTAINED}
            onClick={onGoTiStuidesClick}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.info.selectedBorder}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
          >
            Zum Studien
          </Button>
        </Box>
      </StyledLeftContainer>
      <StyledRightContainer>
        <Typography variant="h2">
          FondsConsult-Datenbank - innovativ und intuitiv
        </Typography>
        <Box display="flex" flexDirection="column" gap={5}>
          <StyledTypography variant="body">
            Unsere intuitive Datenbank bietet umfassende Informationen zu
            Investmentfonds, einschließlich Private Markets und ELTIFs. Mit
            unserem Smart Fund Benchmarking identifizieren Sie schnell die USPs
            jedes Fonds. Zusätzlich erhalten Sie direkten Zugang zu unseren
            Experten über den Analyst Desk – für fundierte Entscheidungen und
            prompte Unterstützung.
          </StyledTypography>
          <Button
            variant={ButtonVariant.SECONDARY}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.info.selectedBorder}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
            onClick={onContactClick}
          >
            Kontaktieren Sie uns
          </Button>
        </Box>
      </StyledRightContainer>
    </StyledContainer>
  );
};
