import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledLogoProps } from './Header.d';
import { Box } from '../Box';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(2)} ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    padding: ${SIZES.spacing(1.4)} ${SIZES.spacing(8)};
    justify-content: space-between;
  }
`;

export const StyledLink = styled(Link)<StyledLogoProps>`
  &:hover {
    ${({ isClickable }) => !isClickable && 'cursor: unset;'}
  }
`;

export const StyledLeftContainer = styled(Box)`
  justify-content: space-between;
  width: 100%;

  ${SIZES.media.phone} {
    width: unset;
    gap: ${SIZES.spacing(12.5)};
  }
`;
