import { FC } from 'react';
import { HeaderProps } from './Header.d';
import { ReactComponent as LightLogo } from 'assets/light-logo.svg';
import { ReactComponent as DarkLogo } from 'assets/logo.svg';
import { Box } from '../Box';
import {
  StyledContainer,
  StyledLeftContainer,
  StyledLink,
} from './Header.styled';
import { AppPaths } from '../../../urls/frontend';
import { useLocation } from 'react-router-dom';
import { NavigationMenu } from '../NavigationMenu';
import { Button } from '../Button';
import { ButtonVariant } from '../Button/Button.enums';
import { APP_URL } from 'urls/api';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  LoginCategory,
  LoginClickedAction,
} from 'utils/google-analytics/events/login';
import { useWindowSize } from 'hooks/useWindowSize';

export const Header: FC<HeaderProps> = ({
  variant = 'primary',
  expandableMenuVariant = 'primary',
}) => {
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();

  const isHomePage = pathname === AppPaths.main;
  const isPrimaryHeader = variant === 'primary';

  const onLoginClick = () => {
    TrackGoogleAnalyticsEvent(
      LoginCategory,
      LoginClickedAction,
      window.location.pathname,
    );
    window.location.href = APP_URL;
  };

  return (
    <StyledContainer display="flex">
      <StyledLeftContainer display="flex" alignItems="center">
        <StyledLink to={AppPaths.main} isClickable={!isHomePage}>
          {isPrimaryHeader ? <DarkLogo /> : <LightLogo />}
        </StyledLink>
        <NavigationMenu
          variant={variant}
          expandableMenuVariant={expandableMenuVariant}
        />
      </StyledLeftContainer>
      {!isMobile && (
        <Box display="flex" gap={1.1} alignItems="center">
          <Button
            variant={
              isPrimaryHeader ? ButtonVariant.SECONDARY : ButtonVariant.OUTLINED
            }
            onClick={onLoginClick}
          >
            Anmelden
          </Button>
          {/* <Button
          variant={
            isPrimaryHeader ? ButtonVariant.PRIMARY : ButtonVariant.CONTAINED
          }
        >
          Sign up
        </Button> */}
        </Box>
      )}
    </StyledContainer>
  );
};
