import styled from 'styled-components';
import { Box } from '../Box';
import BackgroundImage from 'assets/backgrounds/recommendations.jpg';
import { Typography } from '../Typography';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: auto;
  background-position-x: 94%;
  background-position-y: -35px;

  height: fit-content;

  padding-top: ${SIZES.spacing(8)};
  padding-bottom: ${SIZES.spacing(8)};

  ${SIZES.media.phone} {
    padding-top: ${SIZES.spacing(12.5)};
    padding-bottom: ${SIZES.spacing(12.5)};
    background-position-x: unset;
    background-position-y: unset;

    background-size: cover;
  }
`;

export const StyledDescription = styled(Typography)`
  line-height: 25px;
  width: 90%;

  ${SIZES.media.phone} {
    width: 60%;
  }
`;
