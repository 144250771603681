import styled from 'styled-components';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import HeaderBackground from 'assets/backgrounds/methotology.png';
import HeaderBackgroundMobile from 'assets/backgrounds/methotology-mobile.png';

export const StyledContainer = styled(Box)`
  width: 100%;
  min-height: 47vh;
  background-image: url(${HeaderBackgroundMobile});
  background-size: cover;

  ${SIZES.media.phone} {
    background-image: url(${HeaderBackground});
    min-height: 51vh;
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 52px;
  color: ${COLORS.background.secondary};
  margin-bottom: 24px;

  ${SIZES.media.phone} {
    font-size: 64px;
  }
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 16px;
  line-height: 23px;
  color: ${COLORS.background.secondary};

  ${SIZES.media.phone} {
    font-size: 18px;
    line-height: 35px;
  }
`;
