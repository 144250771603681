import styled from 'styled-components';
import { Box } from '../../common/Box';
import { COLORS } from 'theme/colors';
import BackgroundImage from 'assets/backgrounds/studies.png';
import { Typography } from 'components/common/Typography';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  border-top: 1px solid ${COLORS.stroke.primary};
  padding: ${SIZES.spacing(8)} ${SIZES.spacing(2.5)};

  h2 {
    width: 90%;
  }

  ${SIZES.media.phone} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - 113px);
    padding: ${SIZES.spacing(12.5)} ${SIZES.spacing(8)};

    h2 {
      width: 100%;
    }
  }
`;

export const StyledLeftContainer = styled(Box)`
  border-radius: 8px;
  background-image: url(${BackgroundImage});
  background-size: cover;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)};
  gap: ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    gap: 0;
    padding: ${SIZES.spacing(5)};
  }
`;

export const StyledRightContainer = styled(Box)`
  border-radius: 8px;
  background-color: ${COLORS.background.neutral};

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)};
  margin-top: ${SIZES.spacing(2.5)};
  gap: ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    padding: ${SIZES.spacing(5)};
    margin-top: 0;
    gap: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  line-height: 29px;
`;
