import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { HashLink as Link } from 'react-router-hash-link';
import { Box } from 'components/common/Box';
import { SIZES } from 'theme/sizes';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.spacing(2)};

  ${SIZES.media.phone} {
    gap: ${SIZES.spacing(3)};
  }
`;

export const StyledInputsContainer = styled(Box)`
  flex-direction: column;
  gap: ${SIZES.spacing(2)};

  ${SIZES.media.phone} {
    flex-direction: row;
    gap: ${SIZES.spacing(2.5)};
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 11px;
  line-height: 18px;

  text-align: center;
  margin-top: ${SIZES.spacing(2)};

  ${SIZES.media.phone} {
    margin-top: 0;
    width: 60%;
  }
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.typography.description};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;

export const StyledButtonContainer = styled(Box)`
  ${SIZES.media.phone} {
    width: 26%;
    margin: 0 auto;
  }
`;
