import { Box } from 'components/common/Box';
import { FC } from 'react';
import {
  StyledContainer,
  StyledHeader,
  StyledTeamMembersContainer,
  StyledTextContainer,
  StyledTypography,
} from './Team.styled';
import { COLORS } from 'theme/colors';
import { teamMembers } from './config';
import { TeamMember } from './TeamMember';
import { navigationItems } from 'views/AboutUs/config';

export const Team: FC = () => {
  const { team } = navigationItems;

  return (
    <StyledContainer id={team.id}>
      <StyledTextContainer>
        <StyledHeader variant="h2" textAlign="center">
          Das Team
        </StyledHeader>
        <StyledTypography
          variant="body"
          color={COLORS.typography.description}
          textAlign="center"
        >
          Unser erfahrenes Team, bestehend aus einer Führungsmannschaft mit
          durchschnittlich über 20 Jahren Branchenerfahrung und
          hochqualifizierten Analysten, vereint umfassende Expertise in
          Investment-Analyse und Beratung. Die Stabilität unseres Teams
          gewährleistet kontinuierliche Qualität und Zuverlässigkeit in allen
          Aspekten unserer Dienstleistungen, stets ausgerichtet auf die
          spezifischen Anforderungen unserer Kunden.
        </StyledTypography>
      </StyledTextContainer>
      <StyledTeamMembersContainer display="grid">
        {teamMembers.map((item) => (
          <TeamMember
            key={item.id}
            name={item.name}
            position={item.position}
            photo={item.photo}
            id={item.id}
            linkedingLink={item.linkedingLink}
          />
        ))}
      </StyledTeamMembersContainer>
    </StyledContainer>
  );
};
